import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { useCookies } from 'react-cookie-latest';
import styled from 'styled-components/macro';

import { MOBILE_LANDSCAPE, TABLET_PORTRAIT } from '../../constants/mediaQueries';
import { useSelector } from '../../redux';
import {
  ADULT_CONTENT_COOKIE,
  getAffiliateCookies,
  OPTANON_CONSENT_CLOSED_COOKIE,
  OPTANON_CONSENT_COOKIE,
} from 'src/shared/constants/cookies';
import { UserTypes } from 'src/shared/constants/userTypes';

// This is for SegPay - disabled via `featureFlags.enableAdultContentDisclaimerJSON`
export const AdultContentDisclaimerBanner = () => {
  const { cdnUrl, config, location: ipLocation } = useSelector((state) => state.app);
  const { loaded: userLoaded, userType } = useSelector((state) => state.auth);
  const site = useSelector((state) => state.site);

  const [hiddenByReferral, setHiddenByReferral] = useSessionStorage<boolean>('adultDisclaimerHidden');
  const [cookies, setCookie] = useCookies([
    ADULT_CONTENT_COOKIE,
    OPTANON_CONSENT_COOKIE,
    OPTANON_CONSENT_CLOSED_COOKIE,
    ...getAffiliateCookies(site?.abbreviation),
  ]);
  const hasAdultContentCookie = cookies[ADULT_CONTENT_COOKIE];
  const optanonCookie = cookies[OPTANON_CONSENT_COOKIE];
  const optanonClosedCookie = cookies[OPTANON_CONSENT_CLOSED_COOKIE];

  const [isCookieBannerAvailable, setIsCookieBannerAvailable] = useState(false);
  const [bottomOffset, setBottomOffset] = useState<number>(0);

  const isUS = ipLocation?.country === 'US';
  const isGuest = userType === UserTypes.Guest;

  const doNotDisplayBanner = Boolean(
    !config?.featureFlags?.enableAdultContentDisclaimer ||
      !isUS ||
      !userLoaded ||
      !isGuest ||
      hasAdultContentCookie ||
      hiddenByReferral,
  );

  useEffect(() => {
    if (!doNotDisplayBanner && !hiddenByReferral) {
      setHiddenByReferral(true);
    }
  }, [doNotDisplayBanner, hiddenByReferral, setHiddenByReferral]);

  // Detecting if there's a cookie banner
  useEffect(() => {
    // Try to avoid the observer below as much as possible
    if (!optanonCookie || optanonClosedCookie || doNotDisplayBanner) return;

    // Watch for DOM changes until OneTrust banner is available.
    const observer = new MutationObserver((mutation, observer) => {
      const el = document.getElementById('onetrust-banner-sdk');

      if (el) {
        setIsCookieBannerAvailable(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true });
  }, [doNotDisplayBanner, optanonClosedCookie, optanonCookie]);

  // Adjust offset if there's a cookie banner
  useEffect(() => {
    if (!isCookieBannerAvailable) return;

    const bannerEl = document.getElementById('onetrust-banner-sdk');
    const resizeListener = () => {
      setBottomOffset(bannerEl?.offsetHeight || 0);
    };

    window.addEventListener('resize', resizeListener);

    window.OneTrust.OnConsentChanged(() => {
      if (window.OneTrust.IsAlertBoxClosed()) {
        setBottomOffset(0);
      } else {
        setBottomOffset(bannerEl?.offsetHeight || 0);
      }
    });

    if (!window.OneTrust.IsAlertBoxClosed()) {
      setBottomOffset(bannerEl?.offsetHeight || 0);
    }

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isCookieBannerAvailable]);

  const onAcceptClick = () => {
    const expires = new Date('Fri, 31 Dec 9999 23:59:59 GMT');
    setCookie(ADULT_CONTENT_COOKIE, true, { expires, path: '/' });
  };

  const onDenyClick = () => {
    window.location.assign('https://google.com');
  };

  if (doNotDisplayBanner) {
    return null;
  }

  return (
    <BannerWrapper bottomOffset={bottomOffset}>
      <Disclaimer>
        <img src={`${cdnUrl}/img/18+.svg`} alt="18+" />
        <div>
          This site contains sexually explicit material. By using this website you acknowledge that you are over 18.
        </div>
      </Disclaimer>
      <ActionGroup>
        <AgreeButton onClick={onAcceptClick}>I Agree</AgreeButton>
        <LeaveText onClick={onDenyClick}>I'll leave</LeaveText>
      </ActionGroup>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div<{ bottomOffset: number }>`
  width: 100vw;
  height: 140px;
  position: fixed;
  // -1 prevents a pixel gap on mobile view between the 2 banners
  bottom: ${(props) => `${props.bottomOffset > 0 ? props.bottomOffset - 1 : props.bottomOffset}px`};
  right: 0;
  padding: 10px 15px 10px 13px;
  z-index: 999999;

  background-color: #4b4b4b;
  color: #fff;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;

  @media screen and ${MOBILE_LANDSCAPE} {
    padding: 15px 20px 15px 18px;
    flex-direction: row;
    height: 90px;
  }

  @media screen and ${TABLET_PORTRAIT} {
    flex-direction: column;
    height: 140px;
    width: 380px;
    bottom: ${(props) => `${props.bottomOffset}px`};
  }
`;

const Disclaimer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  gap: 10px;
  justify-content: center;

  & div {
    margin-top: 1px;
  }
`;

const ActionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AgreeButton = styled.button`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.2) 100%), #00c166;
  border-radius: 1px;
  border: none;
  width: 155px;
  height: 40px;
  font-size: 0.75rem;
`;

const LeaveText = styled.span`
  font-size: 0.5rem;
  text-align: center;
  cursor: pointer;
`;
