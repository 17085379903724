import { Modal } from 'src/client/components/modals/Modal/Modal';
import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  onHide: () => void;
  privacyUrl: string;
  show: boolean;
};

export const FooterBarModal = (props: Props) => {
  return (
    <ModalWrapper modalBodyClassName="more-info-modal-body" onHide={props.onHide} show={props.show}>
      <Title>Delivered right to your inbox!</Title>
      <Paragraph>
        The MetArt Network uses your email address to send you more information about our products/services, events,
        news and offers.
      </Paragraph>
      <Paragraph>
        You can unsubscribe at any time by using the unsubscribe link located in our emails we send you.
      </Paragraph>
      <Paragraph>
        To learn more please see our{' '}
        <PrivacyPolicyLink href={props.privacyUrl} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </PrivacyPolicyLink>
        .
      </Paragraph>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Modal)`
  .more-info-modal-body {
    background-color: #fff;
    color: #000;
    display: inline-block;
    min-height: 0;
`;

const Title = styled.h4`
  border-bottom: 1px solid lightgrey;
  border-top: 0;
  color: #000;
  font-size: 1.3rem;
  font-weight: bold;
  padding: 8px 0 18px;
  margin: 0 0 18px;
  text-transform: none;
`;

const Paragraph = styled.p`
  padding: 0 8px;
`;

const PrivacyPolicyLink = styled.a`
  &,
  &:active,
  &:focus,
  &:visited {
    color: ${(props) => props.theme.primaryBtn1Bg};
    text-decoration: underline;
  }
  &:hover {
    color: ${(props) => props.theme.primaryBtn1HoverBg};
    text-decoration: underline;
  }
`;
