import React from 'react';
import styled from 'styled-components/macro';
import { JoinButton } from 'src/client/components/buttons/JoinButton';
import { TABLET_LANDSCAPE } from 'src/client/constants/mediaQueries';
import { useSelector } from 'src/client/redux';
import { AdvancedModal } from '@tovia/man-ui';
import { ModalDialog } from '@tovia/man-ui/lib/components/Modal/AdvancedModal';
import { UpgradeStripWrapper } from './UpgradeStrip';

export const NonNudeInfoStrip = () => {
  const { name } = useSelector((state) => state.site);
  const { nonNudeInfoModalText } = useSelector((state) => state.app.config);
  const [modalOpen, setModalOpen] = React.useState(false);

  const hideLearnMore = !nonNudeInfoModalText;

  return (
    <UpgradeStripWrapper>
      <SmallScreen>
        <JoinButton campaign="guest-verify-ribbon">
          <ExplicitText>Unlock fully explicit {name} content</ExplicitText>
        </JoinButton>
        <span hidden={hideLearnMore}>
          {' - '}
          <LearnMore onClick={() => setModalOpen(true)}>Learn more</LearnMore>
        </span>
      </SmallScreen>

      {/* Just use separate duplicated text for the small screens vs large screens */}
      {/* so we don't have to do some fake link weirdness to get the first half to */}
      {/* only be a link some of the time. */}
      <LargeScreen>
        <ExplicitText>Unlock fully explicit {name} content</ExplicitText>
        <VerifyText>
          {' - '}
          <JoinButton campaign="guest-verify-ribbon">
            <span>Become a member to verify you are 18 or older</span>
          </JoinButton>
        </VerifyText>
        <span hidden={hideLearnMore}>
          {' - '}
          <LearnMore onClick={() => setModalOpen(true)}>Learn more</LearnMore>
        </span>
      </LargeScreen>

      <StyledModal DialogComponent={StyledDialog} show={modalOpen} onClose={() => setModalOpen(false)}>
        <Title>{nonNudeInfoModalText?.title}</Title>
        <p>{nonNudeInfoModalText?.text}</p>
        <p>{nonNudeInfoModalText?.ending}</p>

        <StyledJoinButton className="btn btn-primary join-btn" campaign="guest-verify-modal">
          Join Now
        </StyledJoinButton>
      </StyledModal>
    </UpgradeStripWrapper>
  );
};

const ExplicitText = styled.span`
  text-decoration: underline;

  @media ${TABLET_LANDSCAPE} {
    text-decoration: none;
  }
`;

const VerifyText = styled.span`
  span {
    text-decoration: underline;
  }
`;

const SmallScreen = styled.div`
  display: block;
  @media ${TABLET_LANDSCAPE} {
    display: none;
  }
`;

const LargeScreen = styled.div`
  display: none;
  @media ${TABLET_LANDSCAPE} {
    display: block;
  }
`;

const LearnMore = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.25em;
`;

const StyledModal = styled(AdvancedModal)`
  text-align: center;
`;

const StyledDialog = styled(ModalDialog)`
  max-width: min(70vw, 650px);
`;

const Title = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledJoinButton = styled(JoinButton)`
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;
